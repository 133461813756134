<template>
	<div>
		<v-tooltip top>
			<template v-slot:activator="{ on, attrs }">
				<v-btn 
					v-bind="attrs" 
					v-on="on"
					@click="dialogDeleteEnrolled = true"
					:disabled="user.role === 'admin'"
					color="error"
					class="mx-2"
					icon
					fab
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
			<span>Remove User</span>
		</v-tooltip>

		<v-dialog v-model="dialogDeleteEnrolled" max-width="450px">
			<v-card>
				<v-card-title>
					<v-icon class="mr-2">mdi-alert</v-icon>
					{{ `${user.nickname}/${user.id}` }}
				</v-card-title>

				<v-card-text>
					Are you sure you want to delete this streamer from the platform?
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="dialogDeleteEnrolled = false">Cancel</v-btn>
					<v-btn color="error" :loading="loadingDeleteBtn" :disabled="loadingDeleteBtn" @click="DeleteEnrolled(user.id)">Delete</v-btn>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: ["user"],

	data() {
		return {
			dialogDeleteEnrolled: false,
			loadingDeleteBtn: false,
		}
	},

	methods: {
		async DeleteEnrolled(id) {
			try {
				this.loadingDeleteBtn = true;

				const request = await fetch(`${this.$store.state.base_url}/enrolled/remove`, {
					method: 'DELETE',
					body: JSON.stringify({
						enrolled_id: id
					}),
					headers: {
						"Content-type" : "application/json; charset=UTF-8",
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});

				const res = await request.json();

				if (request.ok) {	
					if (res.isEnrolledDeleted) {
						this.$emit('EnrolledDeleted');
						this.$notify({
							group: 'main',
							type: 'success',
							title: this.$store.state.app_name,
							text: 'Enrolled user removed!'
						});
					}
					else {
						this.$notify({
							group: 'main',
							type: 'error',
							title: this.$store.state.app_name,
							text: res.error
						});
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: 'There was a network error. Please try again.'
				});
			}
			finally {
				this.loadingDeleteBtn = false;
			}
		}
	},
}
</script>